<template>
  <div id="app">
    <div class="Signature__Pad">
      <vueSignature
        ref="signature"
        :sigOption="option"
        :w="'800px'"
        :h="'400px'"
        :disabled="disabled"
        :defaultUrl="dataUrl"
      />
      <!-- <div>
        <button @click="save">Save</button>
        <button @click="clear">Clear</button>
        <button @click="undo">Undo</button>
        <button @click="addWaterMark">addWaterMark</button>
        <button @click="handleDisabled">disabled</button>
      </div> -->
    </div>
  </div>
</template>

<script>
import vueSignature from "vue-signature";

export default {
  name: "app",
  components: {
    vueSignature,
  },
  data() {
    return {
      option: {
        penColor: "rgb(0, 0, 0)",
        // backgroundColor: "rgba(255,255,255, 0)",
      },
      disabled: false,
      // dataUrl: 'https://avatars2.githubusercontent.com/u/17644818?s=460&v=4',
    };
  },
  methods: {
    save() {
      const png = this.$refs.signature.save();
      //   let jpeg = this.$refs.signature.save('image/jpeg');
      //   let svg = this.$refs.signature.save('image/svg+xml');
      console.log(png);
      //   console.log(jpeg);
      //   console.log(svg);
    },
    clear() {
      this.$refs.signature.clear();
    },
    undo() {
      this.$refs.signature.undo();
    },
    addWaterMark() {
      this.$refs.signature.addWaterMark({
        text: "mark text", // watermark text, > default ''
        font: "20px Arial", // mark font, > default '20px sans-serif'
        style: "all", // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
        fillStyle: "red", // fillcolor, > default '#333'
        strokeStyle: "blue", // strokecolor, > default '#333'
        x: 100, // fill positionX, > default 20
        y: 200, // fill positionY, > default 20
        sx: 100, // stroke positionX, > default 40
        sy: 200, // stroke positionY, > default 40
      });
    },
    fromDataURL() {
      this.$refs.signature.fromDataURL("data:image/png;base64,iVBORw0K...");
    },
    handleDisabled() {
      this.disabled = !this.disabled;
    },
  },
};
</script>
<style scoped lang="scss">
.Signature__Pad {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cccccc;

  > div {
    width: 800px;
    height: 400px;
    box-shadow: 0px 0px 30px #9e9e9e;
    border: 1px solid darkgrey;
  }
}
</style>
